import {defineStore} from 'pinia'
import {useConfigurationService} from '@/composables/configuration_service'
import {useUserStore} from './user'
import {useCartStore} from './cart'
import {CONFIGURATION_KEYS, MAX_CART_ITEMS_FALLBACK_VALUE} from 'assets/js/constants'

export const useConfigurationDataStore = defineStore('ConfigurationDataModule', {
  persist: {
    storage: persistedState.cookies,
    paths: ['experimentalMode', 'acceptedHydraulicSelectorDisclaimer', 'acceptedAccumulatorSelectorDisclaimer']
  },
  state: () => ({
    configurations: [],
    configurationAge: 0,
    configurationCustomer: 'none',
    experimentalMode: false,
    acceptedHydraulicSelectorDisclaimer: false,
    acceptedAccumulatorSelectorDisclaimer: false,
    newSearch: false
  }),
  actions: {
    async fetchConfigurations() {
      const CONFIGURATION_MAX_AGE = useRuntimeConfig().public.CONFIGURATION_MAX_AGE || 3600000
      const {getConfigurationsByScope} = useConfigurationService()
      const app = useNuxtApp()
      const currentTime = new Date().getTime()
      const userStore = useUserStore()
      const currentCustomerUuid = userStore.loadedCustomerUuid
      if (this.configurationCustomer !== currentCustomerUuid || currentTime > this.configurationAge + CONFIGURATION_MAX_AGE) {
        const configs = await getConfigurationsByScope('frontend', app.$globalization.getRegion())
        this.configurations = configs
        this.configurationAge = currentTime
        this.configurationCustomer = currentCustomerUuid
      }
      // set cart maxitems after configurations are loaded
      useCartStore().maxCartItems = this.configurations.find(c => c.key === CONFIGURATION_KEYS.MAX_CART_ITEMS_KEY)?.value ?? MAX_CART_ITEMS_FALLBACK_VALUE
    }
  }
})
