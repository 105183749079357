import {useConfigurationDataStore} from '@/store/configuration-data'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const configStore = useConfigurationDataStore()
  configStore.newSearch = from?.query?.newsearch === '1' || to?.query?.newsearch === '1'
  if (from?.query?.newsearch !== undefined && to?.query?.newsearch === undefined) {
    if (to.fullPath.includes('?')) {
      to.fullPath += `&newsearch=${from?.query.newsearch}`
    } else {
      to.fullPath += `?newsearch=${from?.query.newsearch}`
    }
    return navigateTo(to.fullPath)
  }
})
