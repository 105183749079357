//Only Images that includes the following tags should in the image gallery
export const GALLERY_TAG_WHITELIST = [
  'Name:DesignInstallationSketch',
  'eCom',
  'Digital Business Development',
  'Installation Sketch',
  'Category:B1',
  '268x168'
]
export const Tags = Object.freeze({
  CadDownload: 'CadDownload',
  Category: 'Category:',
  DesignSketch: 'Name:DesignSketch',
  DesignInstallationSketch: 'Name:DesignInstallationSketch'
})
export const RoleScope = 'both'

export const USER_TYPE = Object.freeze({
  Internal: 'internal',
  External: 'external'
})

export const TOLERANCE_SET = Object.freeze({
  ToleranceArray: [
    {
      tolerance: '+/- 0%',
      value: 0
    },
    {
      tolerance: '+/- 1%',
      value: 1
    },
    {
      tolerance: '+/- 2%',
      value: 2
    },
    {
      tolerance: '+/- 5%',
      value: 5
    },
    {
      tolerance: '+/- 10%',
      value: 10
    },
    {
      tolerance: '+/- 25%',
      value: 25
    }
  ]
})

export const CustomerCurrencyFormat = 'Customer:CurrencyFormat'

export const ADDRESS_TYPE = Object.freeze({
  Delivery: 'Delivery',
  Invoice: 'Invoice'
})

export const POPUP = Object.freeze({
  ZIndex: 500,
  Size: {small: 'small', medium: 'medium', large: 'large'}
})

export const DROP_DOWN = Object.freeze({
  DataItemsProperty: 'data-items',
  FilterProperty: 'filterable',
  OpenIconClass: 'open'
})

export const FALLBACK_IMAGE = '/img/no_image.png'

export const BASE_EBC_URL_FALLBACK = 'https://ebc.fst.com'

export const SEARCH = Object.freeze({
  MAX_FACET_DESIGNS: 4
})

export const PRODUCT_UNITS = {
  MTR: 'MTR',
  M: 'M',
  PCE: 'PCE',
  PCS: 'PCS',
  KG: 'KG',
  ST: 'ST',
  M2: 'M2'
}

export const SIDEBAR_ACTIONS = Object.freeze({
  Contact: 'open_contact_form',
  Feedback: 'open_feedback_form'
})

export const USER_SETTING_KEYS = Object.freeze({
  ReceiveNotifications: 'User:ReceiveNotifications',
  ReceiveMails: 'User:ReceiveMails',
  CommunicationLanguage: 'User:CommunicationLanguage',
  PreferredMeasurement: 'User:PreferredMeasurement',
  UserType: 'User:UserType'
})

export const CUSTOMER_SETTING_KEYS = Object.freeze({
  BulkOrderAllowed: 'Customer:BulkOrderAllowed',
  NordOrderAllowed: 'Customer:NordOrderAllowed',
  ExpoOrderAllowed: 'Customer:ExpressOrderAllowed',
  BulkPreselect: 'Customer:BulkPreselect',
  ExpectedPriceEnabled: 'Customer:ExpectedPriceEnabled',
  EndCustomerReferenceEnabled: 'Customer:EndCustomerReferenceEnabled',
  ProformaInvoice: 'Customer:EnableProformaPrice',
  IsRecommSalesPriceVisible: 'Customer:IsRecommSalesPriceVisible',
  ReceiveNotifications: 'UserCustomer:ReceiveNotifications',
  ReceiveMails: 'UserCustomer:ReceiveMails',
  ReceiveOrderConfirmationMail: 'UserCustomer:ReceiveOrderConfirmationMail',
  ReceiveOrderCopyMail: 'UserCustomer:ReceiveOrderCopyMail',
  ReceiveRfqCopyMail: 'UserCustomer:ReceiveRFQCopyMail',
  CpdAccount: 'Customer:CPDAccount',
  DeliveryPriority: 'Customer:DeliveryPriority',
  DeliveryPaidByFST: 'Customer:DeliveryPaidByFST',
  SwitchOffRounding: 'Customer:SwitchOffRounding',
  IncotermOfAg: 'Customer:IncotermOfAg',
  XpressAllowed: 'Customer:XPressAllowed',
  PreselectCustomerArticleNumberSearch: 'Customer:PreselectCustomerArticleNumberSearch'
})

export const USER_SETTING_VALUES = Object.freeze({
  UserModes: {
    NormalMode: 'NormalMode',
    ExpertMode: 'ExpertMode'
  }
})

export const CUSTOMER_SETTING_VALUES = Object.freeze({
  DeliveryPriority: {
    Deactivated: 'deliverypriority-allairallsea-deactivated'
  }
})

export const SettingsKeyEBCFileFormat = 'Customer:EBCFileFormat'

export const VisibleSettings = Object.freeze({
  ProfilePage: ['User:UiMode', 'User:PreferredMeasurement'],
  AppSettingsPage: [
    'User:UiMode',
    'UserProperty:Timezone',
    'User:DateFormat',
    'User:PreferredMeasurement',
    'User:CommunicationLanguage'
  ],
  CartPage: ['User:UiMode'],
  EBCSettingsPage: [
    'Customer:EBCFileFormat',
    'Customer:EBCOrderResponseFilterNewOrderLines',
    'Customer:EBCOrderResponseCancelledOrderLinesHandling',
    'Customer:EBCUsesOwnArticleNumber',
    'Customer:EBCErrorHandling',
    'Customer:EBCErrorMail',
    'Customer:EBCOrderMode',
    'Customer:EBCDELINSMode',
    'Customer:EBCBlockOrderWhenRoundingUp',
    'Customer:EBCFileCreation'
  ],
  AgreementStepSettings: [
    'User:AllowEASYNewsletter',
    'User:AcceptEASYTermsAndConditions',
    'User:DataPrivacyAccepted'
  ],
  UserClassificationStepSettings: [
    'User:CompanyName',
    'User:CompanySegment',
    'User:CompanySize',
    'User:RoleInCompany',
    'User:CompanyCountry',
    'User:CompanyZIPCode'
  ],
  SupplierStepSettings: ['User:CompanyFSTSupplierCode'],
  ApplicationStepSettings: [
    'User:UiMode',
    'UserProperty:Timezone',
    'User:DateFormat',
    'User:PreferredMeasurement',
    'User:CommunicationLanguage'
  ],
  SettingsInThisCompanyArea: [
    CUSTOMER_SETTING_KEYS.ReceiveMails,
    CUSTOMER_SETTING_KEYS.ReceiveOrderCopyMail,
    CUSTOMER_SETTING_KEYS.ReceiveOrderConfirmationMail,
    CUSTOMER_SETTING_KEYS.ReceiveRfqCopyMail,
    'UserCustomer:PackagingVariant'
  ],
  GeneralInformationArea: [
    'Customer:NordOrderAllowed',
    CUSTOMER_SETTING_KEYS.BulkOrderAllowed,
    'Customer:ExpressOrderAllowed',
    CUSTOMER_SETTING_KEYS.EndCustomerReferenceEnabled,
    CUSTOMER_SETTING_KEYS.ExpectedPriceEnabled,
    CUSTOMER_SETTING_KEYS.SwitchOffRounding,
    CUSTOMER_SETTING_KEYS.PreselectCustomerArticleNumberSearch,
    'Customer:UnloadingPoint',
    'Customer:OrderCopyRecipients',
    CUSTOMER_SETTING_KEYS.BulkPreselect,
    CUSTOMER_SETTING_KEYS.DeliveryPriority,
    CUSTOMER_SETTING_KEYS.CpdAccount,
    CUSTOMER_SETTING_KEYS.IsRecommSalesPriceVisible,
    CUSTOMER_SETTING_KEYS.ProformaInvoice,
    'Customer:CurrencyFormat',
    CUSTOMER_SETTING_KEYS.XpressAllowed
  ]
})

export const READ_ONLY_SETTINGS = Object.freeze({
  GeneralInformationArea: [CUSTOMER_SETTING_KEYS.SwitchOffRounding, CUSTOMER_SETTING_KEYS.CpdAccount]
})

export const CATEGORIES = Object.freeze({
  RootCategoriesUrl: '/categories'
})

export const TermsAndConditionKey = 'User:AcceptEASYTermsAndConditions'

export const DataPrivacyAcceptedKey = 'User:DataPrivacyAccepted'

export const RequiredSupplierSetting = 'User:CompanyFSTSupplierCode'

export const EBCSettingsFileExtensions = Object.freeze({
  opentrans: '.xml',
  xml: '.xml',
  csv: '.csv'
})

export const EBCSettingsSamples = [
  {
    label: 'ordersOpentransExtended',
    url:
      'https://cdne-enextcms-dev.azureedge.net/medialibrary/assets/easy2_order_customer_sample_00caacd0fe.xml'
  },
  {
    label: 'ordersOpentransMinimum',
    url:
      'https://cdne-enextcms-dev.azureedge.net/medialibrary/assets/easy2_order_customer_minimum_52c0d7ff94.xml'
  },
  {
    label: 'ordersCSVExtended',
    url:
      'https://cdne-enextcms-dev.azureedge.net/medialibrary/assets/CSV_extended_d06a9ce505.csv'
  },
  {
    label: 'ordersCSVMinimum',
    url:
      'https://cdne-enextcms-dev.azureedge.net/medialibrary/assets/CSV_minimum_420459d6e8.csv'
  },
  {
    label: 'deliveryOpentransExtended',
    url:
      'https://cdne-enextcms-dev.azureedge.net/medialibrary/assets/easy2_delins_6017c6f208.xml'
  },
  {
    label: 'deliveryOpentransMinimum',
    url:
      'https://cdne-enextcms-dev.azureedge.net/medialibrary/assets/easy2_delins_min_93f20a33ec.xml'
  }
]
export const LoginWizardDoneSetting = 'User:LoginWizardDone'
export const CountrySettings = ['User:CompanyCountry']

export const ADDITEM_STATUS = Object.freeze({
  PENDING: 0,
  SUCCESS: 1,
  ERROR: 2
})

export const CHUNK_SIZES = Object.freeze({
  ProductService: 1000,
  PandaService: 25
})

export const PRODUCT_ATTRIBUTES = Object.freeze({
  Rohs: 'ROHS',
  Reach: 'REACH'
})

export const MIN_MAX_VALUES = Object.freeze({
  Quantity: {
    Min: 0.01,
    Max: 9999999
  },
  QuoteReference: {
    MaxLength: 50
  },
  PositionNote: {
    MaxLength: 255
  }
})

export const PLANT = Object.freeze({
  DEFAULT_PLANT_OBJECT: {
    alternativePlantGuid: 'default-plant',
    alternativePlant: 'default-plant',
    alternativePlantName: 'defaultPlant',
    plant: null,
    isDefault: true
  },
  PLANT_TRANSLATION_KEY: 'plants'
})

export const ITEMS = Object.freeze({
  QUANTITY_WARNINGS: [
    'Quantity_Set_To_Min_Order_Quantity',
    'Quantity_Reduced',
    'Quantity_Rounded'
  ],
  VALIDATION_TYPES: {
    NONE: 'NONE',
    INFO: 'INFO',
    ERROR: 'ERROR',
    WARNING: 'WARNING',
    OK: 'OK'
  },
  AVAILABILITY_DATE_MESSAGES: {
    NONE: 'NONE',
    NOT_LOADED: 'NOT_LOADED',
    DATEPASTINVALID: 'DATEPASTINVALID',
    DATEFUTUREINVALID: 'DATEFUTUREINVALID'
  }
})

export const EXCEL_IMPORT = Object.freeze({
  TYPES: {
    USER: 'USER_IMPORT',
    PRODUCT: 'PRODUCT_IMPORT'
  }
})

export const DELIVERY_STATUS_VALUES = Object.freeze([
  {
    status: 'Rejected',
    value: 0
  },
  {
    status: 'Open',
    value: 0
  },
  {
    status: 'Confirmed',
    value: 1
  },
  {
    status: 'InProgress',
    value: 1
  },
  {
    status: 'ShippingInPreparation',
    value: 2
  },
  {
    status: 'Delivered',
    value: 3
  },
  {
    status: 'Shipped',
    value: 3
  }
])

export const SHIPMENT_STATUS_VALUES = Object.freeze([
  {
    status: 'AttempFail',
    value: 0
  },
  {
    status: 'Unknown',
    value: 0
  },
  {
    status: 'Expired',
    value: 0
  },
  {
    status: 'Exception',
    value: 0
  },
  {
    status: 'Pending',
    value: 0
  },
  {
    status: 'InfoReceived',
    value: 1
  },
  {
    status: 'AvailableForPickup',
    value: 1
  },
  {
    status: 'InTransit',
    value: 2
  },
  {
    status: 'OutForDelivery',
    value: 2
  },
  {
    status: 'Delivered',
    value: 3
  }
])

export const ASSET_URL = 'https://apim-enext-stg.azure-api.net/assets/'

export const INCOTERM_PICKUP_CUSTOMER_REGEXP = /^[EF].*/

export const CONFIGURATION_KEYS = Object.freeze({
  SHOW_ALTERNATIVE_PLANTS_KEY: 'Frontend:ShowAlternativePlants',
  SHOW_CERTIFICATES_KEY: 'Frontend:ShowCertificates',
  SHOW_VARIANTS_KEY: 'Frontend:ShowVariants',
  SHOW_SHIPPING_FEE_KEY: 'Frontend:ShowShippingFee',
  ENABLE_CPD: 'Frontend:EnableCpd',
  SHOW_EXPERIMENTAL: 'Frontend:EnableExperimentalFeature',
  MAX_CART_ITEMS_KEY: 'Frontend:MaxCartItems',
  SHOW_SOCIAL_INTENTS_CHAT: 'Frontend:ShowSocialIntentsChat',
  ENABLE_DIRECT_DELIVERY: 'Frontend:EnableDirectDeliveries'
})

export const MAX_CART_ITEMS_FALLBACK_VALUE = 300

export const REGION_KEYS = Object.freeze({
  GLOBAL: 'GLOBAL',
  EU: 'EU',
  NA: 'NA'
})

export const FORM_DEFAULT_DATA = Object.freeze({
  CONTACT_FORM: {
    Salutation: null,
    Type: null,
    Title: null,
    FirstName: null,
    LastName: null,
    Company: null,
    Country: null,
    Street: null,
    ZipCode: null,
    City: null,
    Email: null,
    Phone: null,
    Message: null,
    Language: null,
    AcceptTermsAndConditions: false,
    SubscribeNewsletter: false
  },
  USER_FORM: {
    firstname: '',
    lastname: '',
    email: '',
    roles: [],
    budgetLimit: null,
    budgetLimitFloat: null,
    language: null
  }
})

export const USER_RIGHTS_REGEXP = /\s*\S+\/([a-zA-Z.]+)/g

export const USER_RIGHTS_MAPPING = Object.freeze({
  BUSINESSCONNECTOR_ACCESS: 'BusinessConnector.Access',
  BUSINESSCONNECTOR_GLOBAL_ACCESS: 'BusinessConnector.Global.Access',
  BUSINESSCONNECTOR_GLOBAL_TOKEN_CREATE:
    'BusinessConnector.Global.Token.Create',
  BUSINESSCONNECTOR_SETTESTMODE: 'BusinessConnector.SetTestMode',
  BUSINESSCONNECTOR_TOKEN_CREATE: 'BusinessConnector.Token.Create',
  CAD_DOWNLOAD: 'Product.CAD.Download',
  CART_VIEW: 'Cart.View',
  CCRS_ACCESS_MANAGEMENT: 'CCRS.Access.Management',
  CUSTOMER_BUSINESSCONNECTOR_VIEW: 'Customer.BusinessConnector.View',
  CUSTOMER_BUSINESSCONNECTOR_SETTESTMODE:
    'Customer.BusinessConnector.SetTestMode',
  CUSTOMER_EDIT: 'Customer.Edit',
  CUSTOMER_EDITDELIVERYADDRESS: 'Customer.EditDeliveryAddress',
  CUSTOMER_GLOBAL_EDIT: 'Customer.Global.Edit',
  CUSTOMER_GLOBAL_VIEW: 'Customer.Global.View',
  CUSTOMER_MYSETTINGS_EDIT: 'Customer.Mysettings.Edit',
  CUSTOMER_PRODUCTDATA_VIEW: 'Customer.ProductData.View',
  CUSTOMER_VIEW: 'Customer.View',
  ORDER_HISTORY_VIEW: 'OrderHistory.View',
  ORDER_VIEW: 'Order.View',
  PRODUCT_BUY: 'Product.Buy',
  PRODUCT_DATASHEET_DOWNLOAD: 'Product.Datasheet.Download',
  PRODUCT_REQUEST: 'Product.Request',
  PRODUCT_VIEW_AVAILABILITY: 'Product.Availability.View',
  PRODUCT_VIEW_PRICE: 'Product.Price.View',
  QUOTE_HISTORY_VIEW: 'QuoteHistory.View',
  QUOTE_VIEW: 'Quote.View',
  STS_ACCESS_BACKEND: 'STS.Access.Backend',
  USER_ASSIGN: 'User.Assign',
  USER_CREATE: 'User.Create',
  USER_DEBUG: 'User.Debug',
  USER_DELETE: 'User.Delete',
  USER_EDIT: 'User.Edit',
  USER_GLOBAL_ASSIGN: 'User.Global.Assign',
  USER_GLOBAL_CREATE: 'User.Global.Create',
  USER_GLOBAL_DELETE: 'User.Global.Delete',
  USER_GLOBAL_EDIT: 'User.Global.Edit',
  USER_GLOBAL_VIEW: 'User.Global.View',
  USER_IMPERSONATE: 'User.CanImpersonate',
  USER_MAINTENANCELOGIN: 'User.MaintenanceLogin',
  USER_VIEW: 'User.View'
})

export const BRANDS_MAPPING = Object.freeze({
  BRAND_FREUDENBERG: 'Freudenberg',
  BRAND_DICHTOMATIK: 'Dichtomatik'
})

export const COOKIEBOT_ID = '4737a3d4-beec-47c6-bfc0-27b1c3446a75'

export const GRID_SETTINGS = Object.freeze({
  SCROLL_COLUMN_WIDTH: 45,
  SCROLLBAR_THRESHOLD: {
    TOP: 80,
    BOTTOM: 30,
    MIN_WIDTH: 150
  },
  HIERARCHY_CELL_WIDTH: 32,
  EMPTY_CELL: {
    field: '',
    title: '',
    cell: 'emptyCell',
    sortable: false,
    headerClassName: 'empty-cell'
  },
  APPLY_FILTER_THRESHOLD: {
    TOP: 230
  }
})

export const MOBILE_BREAKPOINT = 768

export const DefaultCustomerUuid = 'https://sso.fst.com/default/v1.0/'

export const MAX_CUSTOMERS = 50

export const REQUEST_ONLY_STATUS = [0, 10, 20, 60, 90]
export const LIMITED_AVAILABILITY_STATUS = 39
export const DISCONTINUED_STATUS = 40

export const NON_ATTRIBUTE_FILTERS = Object.freeze({
  SHORE_HARDNESS: 'ShoreHardnessPrimary',
  POLYMER: 'PolymerPrimary',
  PRIMARYL2CATEGORY: 'PrimaryL2Category',
  BRAND: 'BrandName',
  MATERIALIDENTIFIER: 'MaterialPrimary',
  DESIGN: 'ProductDesign'
})
//for new experimental search
export const NON_ATTRIBUTE_FILTERS_NEW = Object.freeze({
  SHORE_HARDNESS: 'material1ShoreHardness',
  POLYMER: 'material1Compound',
  PRIMARYL2CATEGORY: 'primaryL2Category',
  BRAND: 'brand',
  MATERIALIDENTIFIER: 'material1Name',
  DESIGN: 'productDesign'
})

export const URL_HTTP_REGEXP_CHECK = /^http(s?):\/\/.*/

export const UPLOAD_FILE_STATUS_SUCCESS = 2
export const PREFERRED_MEASUREMENT_IMPERIAL = 'Imperial'
export const PREFERRED_MEASUREMENT_METRIC = 'Metric'

export const CART_ITEM_RENDER_ALL = 5
export const CART_ITEM_BUFFER = 3

export const REGEX_MAPPING = Object.freeze({
  Attributes: /^(Characteristics|Attributes)\/.*/,
  AttributesNew: /^(characteristics)\/.*/,
  MaterialWithIndex: /^materials\/(\d+)\/.*/,
  MaterialIdentifier: /^materials\/\d+\/materialIdentifier/,
  PolymerPrimary: /^materials\/\d+\/compound/,
  ShoreHardnessPrimary: /^materials\/\d+\/shoreHardness/,
  Characteristics: /^(Characteristics|Attributes)\/[\S]*\/(.*)/,
  ToLowerCase: /([a-zA-Z])(\S*)/
})

export const VERSION_POLLING_TIME_IN_MS = 5 * 60 * 1000

export const API_NAMES = Object.freeze({
  panda: 'PANDA',
  orderInfo: 'ORDERINFO'
})

export const APIS_WITH_MAINTENANCE = [API_NAMES.panda, API_NAMES.orderInfo]

export const PROPERTY_SCOPE_NAMES = Object.freeze({
  CART: 'cart',
  CATEGORY_PAGE: 'category-page',
  CATEGORY_PAGE_NEW: 'category-page-new',
  PRODUCT_PAGE: 'product-page',
  PRODUCT_PAGE_NEW: 'product-page-new',
  SEARCH_OVERLAY: 'search-overlay',
  SEARCH_OVERLAY_NEW: 'search-overlay-new',
  SEARCH_RESULT_PAGE: 'search-result-page',
  SEARCH_RESULT_PAGE_NEW: 'search-result-page-new',
  PLANTS_ONLY: 'plants-only',
  PLANTS_ONLY_NEW: 'plants-only-new',
  SUCCESSOR_POPUP: 'successor-popup',
  DESIGN_ONLY: 'design-only',
  VARIANT_ITEMS: 'variant-items',
  VARIANT_ITEMS_NEW: 'variant-items-new',
  HYDRAULIC_SELECTOR: 'hydraulic-selector',
  HYDRAULIC_SELECTOR_NEW: 'hydraulic-selector-new',
  ORDER_HISTORY: 'order-history',
  ORDER_HISTORY_NEW: 'order-history-new'
})

export const PROPERTY_SCOPE_FIELDS = Object.freeze({
  ALTERNATIVE_PLANT_CODE: 'alternativePlantCode',
  ASSETS: 'assets',
  BRAND_NAME: 'brandName',
  CATEGORIES: 'categories',
  CHARACTERISTICS: 'characteristics',
  COP_FNST_SKU: 'copFnstSku',
  CUSTOMER_MAPPINGS: 'customerMappings',
  DEFAULT_PLANT_CODE: 'defaultPlantCode',
  DESIGN_INSTALLATION_SKETCH: 'designInstallationSketch',
  DESIGN_SKETCH: 'designSketch',
  DIMENSION_FLOATS: 'dimensionFloats',
  DIMENSION_STRING: 'dimensionsString',
  INCH_SEARCH: 'inchSearch',
  LEGACYSKU: 'legacysku',
  LOCALES: 'locales',
  MATERIALS: 'materials',
  MATERIAL_PRIMARY: 'materialPrimary',
  MAX_PRICED_QTY: 'maxPricedQty',
  MEASUREMENT: 'measurement',
  NET_WEIGHT: 'netWeight',
  PLANTS: 'plants',
  POLYMER_PRIMARY: 'polymerPrimary',
  PREDECESSOR: 'predecessor',
  PRICING_FLAG: 'pricingFlag',
  PRIMARY_L1_CATEGORY: 'primaryL1Category',
  PRIMARY_L2_CATEGORY: 'primaryL2Category',
  PRODUCT_DESIGN: 'productDesign',
  SALES_AREAS: 'salesAreas',
  SHORE_HARDNESS_PRIMARY: 'shoreHardnessPrimary',
  SKU: 'sku',
  SUCCESSOR: 'successor',
  VARIANT: 'variant',
  VARIANTS: 'otherVariants',
  WEIGHT_UNIT_OF_MEASURE: 'weightUnitOfMeasure'
})
//for new experimental search
export const PROPERTY_SCOPE_FIELDS_NEW = Object.freeze({
  ASSETS: 'assets',
  BRAND_NAME: 'brand',
  CATEGORIES: 'categories',
  CHARACTERISTICS: 'characteristics',
  COP_FNST_SKU: 'copFnstSku',
  CUSTOMER_MAPPINGS: 'customerMappings',
  DESIGN_SKETCH: 'designSketchUri',
  MATERIALS: 'materials',
  MATERIAL_PRIMARY: 'material1Name',
  MAX_PRICED_QTY: 'maxPricedQty',
  NET_WEIGHT: 'netWeight',
  PLANTS: 'plants',
  PRICING_FLAG: 'pricingFlag',
  PRIMARY_L1_CATEGORY: 'primaryL1Category',
  PRIMARY_L2_CATEGORY: 'primaryL2Category',
  PRODUCT_DESIGN: 'productDesign',
  SALES_AREAS: 'salesAreas',
  SKU: 'sku',
  VARIANT: 'variants',
  WEIGHT_UNIT_OF_MEASURE: 'netWeightUnitOfMeasure',
  UNIT_OF_MEASURE: 'unitOfMeasure',
  HIGHLIGHTS: 'highlights'
})

export const PROPERTY_SCOPE_STATIC = Object.freeze({
  FST_DYNAMIC: [],
  ORDER_HISTORY: [PROPERTY_SCOPE_FIELDS.SKU, PROPERTY_SCOPE_FIELDS.LOCALES, PROPERTY_SCOPE_FIELDS.LEGACYSKU,
    PROPERTY_SCOPE_FIELDS.COP_FNST_SKU],
  ORDER_HISTORY_NEW: [PROPERTY_SCOPE_FIELDS_NEW.SKU, PROPERTY_SCOPE_FIELDS_NEW.COP_FNST_SKU],
  BUY_BOX: [PROPERTY_SCOPE_FIELDS.SALES_AREAS, PROPERTY_SCOPE_FIELDS.PLANTS, PROPERTY_SCOPE_FIELDS.PRODUCT_DESIGN,
    PROPERTY_SCOPE_FIELDS.SKU, PROPERTY_SCOPE_FIELDS.SUCCESSOR, PROPERTY_SCOPE_FIELDS.ALTERNATIVE_PLANT_CODE,
    PROPERTY_SCOPE_FIELDS.DEFAULT_PLANT_CODE, PROPERTY_SCOPE_FIELDS.CUSTOMER_MAPPINGS, PROPERTY_SCOPE_FIELDS.MAX_PRICED_QTY,
    PROPERTY_SCOPE_FIELDS.VARIANT, PROPERTY_SCOPE_FIELDS.ASSETS, PROPERTY_SCOPE_FIELDS.VARIANTS, PROPERTY_SCOPE_FIELDS.NET_WEIGHT,
    PROPERTY_SCOPE_FIELDS.WEIGHT_UNIT_OF_MEASURE, PROPERTY_SCOPE_FIELDS.MEASUREMENT, PROPERTY_SCOPE_FIELDS.LEGACYSKU,
    PROPERTY_SCOPE_FIELDS.MATERIALS, PROPERTY_SCOPE_FIELDS.BRAND_NAME, PROPERTY_SCOPE_FIELDS.DIMENSION_STRING, PROPERTY_SCOPE_FIELDS.PRIMARY_L2_CATEGORY
  ],
  BUY_BOX_NEW: [PROPERTY_SCOPE_FIELDS_NEW.SALES_AREAS, PROPERTY_SCOPE_FIELDS_NEW.PLANTS, PROPERTY_SCOPE_FIELDS_NEW.PRODUCT_DESIGN,
    PROPERTY_SCOPE_FIELDS_NEW.SKU, PROPERTY_SCOPE_FIELDS_NEW.CUSTOMER_MAPPINGS, PROPERTY_SCOPE_FIELDS_NEW.MAX_PRICED_QTY,
    PROPERTY_SCOPE_FIELDS_NEW.VARIANT, PROPERTY_SCOPE_FIELDS_NEW.ASSETS, PROPERTY_SCOPE_FIELDS_NEW.NET_WEIGHT,
    PROPERTY_SCOPE_FIELDS_NEW.WEIGHT_UNIT_OF_MEASURE, PROPERTY_SCOPE_FIELDS_NEW.MATERIALS, PROPERTY_SCOPE_FIELDS_NEW.BRAND_NAME,
    PROPERTY_SCOPE_FIELDS_NEW.PRIMARY_L2_CATEGORY, PROPERTY_SCOPE_FIELDS_NEW.UNIT_OF_MEASURE, PROPERTY_SCOPE_FIELDS_NEW.HIGHLIGHTS
  ],
  get CATEGORY_PAGE() {
    return [PROPERTY_SCOPE_FIELDS.CHARACTERISTICS, PROPERTY_SCOPE_FIELDS.BRAND_NAME, PROPERTY_SCOPE_FIELDS.LOCALES,
      PROPERTY_SCOPE_FIELDS.DESIGN_SKETCH, PROPERTY_SCOPE_FIELDS.SHORE_HARDNESS_PRIMARY, PROPERTY_SCOPE_FIELDS.MATERIAL_PRIMARY,
      PROPERTY_SCOPE_FIELDS.POLYMER_PRIMARY, ...this.BUY_BOX, ...this.FST_DYNAMIC]
  },
  get CATEGORY_PAGE_NEW() {
    return [PROPERTY_SCOPE_FIELDS_NEW.CHARACTERISTICS, PROPERTY_SCOPE_FIELDS_NEW.BRAND_NAME,
      PROPERTY_SCOPE_FIELDS_NEW.DESIGN_SKETCH, PROPERTY_SCOPE_FIELDS_NEW.MATERIAL_PRIMARY,
      ...this.BUY_BOX_NEW, ...this.FST_DYNAMIC]
  },
  get SEARCH_OVERLAY() {
    return [PROPERTY_SCOPE_FIELDS.COP_FNST_SKU, PROPERTY_SCOPE_FIELDS.MATERIALS, PROPERTY_SCOPE_FIELDS.DESIGN_SKETCH,
      PROPERTY_SCOPE_FIELDS.DIMENSION_STRING, PROPERTY_SCOPE_FIELDS.MATERIAL_PRIMARY, PROPERTY_SCOPE_FIELDS.LOCALES,
      PROPERTY_SCOPE_FIELDS.MEASUREMENT, PROPERTY_SCOPE_FIELDS.PRIMARY_L2_CATEGORY, PROPERTY_SCOPE_FIELDS.CHARACTERISTICS, ...this.BUY_BOX]
  },
  get SEARCH_OVERLAY_NEW() {
    return [PROPERTY_SCOPE_FIELDS_NEW.COP_FNST_SKU, PROPERTY_SCOPE_FIELDS_NEW.MATERIALS, PROPERTY_SCOPE_FIELDS_NEW.DESIGN_SKETCH,
      PROPERTY_SCOPE_FIELDS_NEW.MATERIAL_PRIMARY, PROPERTY_SCOPE_FIELDS_NEW.PRIMARY_L2_CATEGORY,
      PROPERTY_SCOPE_FIELDS_NEW.CHARACTERISTICS,      PROPERTY_SCOPE_FIELDS_NEW.HIGHLIGHTS, ...this.BUY_BOX_NEW]
  },
  get SEARCH_RESULTS() {
    return [PROPERTY_SCOPE_FIELDS.LEGACYSKU, PROPERTY_SCOPE_FIELDS.COP_FNST_SKU, PROPERTY_SCOPE_FIELDS.CHARACTERISTICS,
      PROPERTY_SCOPE_FIELDS.CUSTOMER_MAPPINGS, PROPERTY_SCOPE_FIELDS.BRAND_NAME, PROPERTY_SCOPE_FIELDS.LOCALES,
      PROPERTY_SCOPE_FIELDS.DESIGN_SKETCH, PROPERTY_SCOPE_FIELDS.MATERIAL_PRIMARY, ...this.BUY_BOX]
  },
  get SEARCH_RESULTS_NEW() {
    return [PROPERTY_SCOPE_FIELDS_NEW.COP_FNST_SKU, PROPERTY_SCOPE_FIELDS_NEW.CHARACTERISTICS,
      PROPERTY_SCOPE_FIELDS_NEW.CUSTOMER_MAPPINGS, PROPERTY_SCOPE_FIELDS_NEW.BRAND_NAME,
      PROPERTY_SCOPE_FIELDS_NEW.DESIGN_SKETCH, PROPERTY_SCOPE_FIELDS_NEW.MATERIAL_PRIMARY, ...this.BUY_BOX_NEW]
  },
  get PRODUCT_DETAIL() {
    return [PROPERTY_SCOPE_FIELDS.MATERIALS, PROPERTY_SCOPE_FIELDS.ASSETS, PROPERTY_SCOPE_FIELDS.BRAND_NAME, PROPERTY_SCOPE_FIELDS.CATEGORIES, PROPERTY_SCOPE_FIELDS.CHARACTERISTICS,
      PROPERTY_SCOPE_FIELDS.COP_FNST_SKU, PROPERTY_SCOPE_FIELDS.CUSTOMER_MAPPINGS, PROPERTY_SCOPE_FIELDS.DESIGN_INSTALLATION_SKETCH,
      PROPERTY_SCOPE_FIELDS.DESIGN_SKETCH, PROPERTY_SCOPE_FIELDS.DIMENSION_STRING, PROPERTY_SCOPE_FIELDS.LEGACYSKU, PROPERTY_SCOPE_FIELDS.LOCALES, PROPERTY_SCOPE_FIELDS.MATERIAL_PRIMARY,
      PROPERTY_SCOPE_FIELDS.MAX_PRICED_QTY, PROPERTY_SCOPE_FIELDS.NET_WEIGHT, PROPERTY_SCOPE_FIELDS.PLANTS, PROPERTY_SCOPE_FIELDS.POLYMER_PRIMARY,
      PROPERTY_SCOPE_FIELDS.PREDECESSOR, PROPERTY_SCOPE_FIELDS.PRICING_FLAG, PROPERTY_SCOPE_FIELDS.PRIMARY_L1_CATEGORY, PROPERTY_SCOPE_FIELDS.PRIMARY_L2_CATEGORY,
      PROPERTY_SCOPE_FIELDS.PRODUCT_DESIGN, PROPERTY_SCOPE_FIELDS.SALES_AREAS, PROPERTY_SCOPE_FIELDS.SHORE_HARDNESS_PRIMARY, PROPERTY_SCOPE_FIELDS.SKU, PROPERTY_SCOPE_FIELDS.VARIANT,
      PROPERTY_SCOPE_FIELDS.WEIGHT_UNIT_OF_MEASURE, PROPERTY_SCOPE_FIELDS.MEASUREMENT, PROPERTY_SCOPE_FIELDS.VARIANTS, PROPERTY_SCOPE_FIELDS.ALTERNATIVE_PLANT_CODE, ...this.BUY_BOX]
  },
  get PRODUCT_DETAIL_NEW() {
    return [PROPERTY_SCOPE_FIELDS_NEW.MATERIALS, PROPERTY_SCOPE_FIELDS_NEW.ASSETS, PROPERTY_SCOPE_FIELDS_NEW.BRAND_NAME, PROPERTY_SCOPE_FIELDS_NEW.CATEGORIES,
      PROPERTY_SCOPE_FIELDS_NEW.CHARACTERISTICS, PROPERTY_SCOPE_FIELDS_NEW.COP_FNST_SKU, PROPERTY_SCOPE_FIELDS_NEW.CUSTOMER_MAPPINGS,
      PROPERTY_SCOPE_FIELDS_NEW.DESIGN_SKETCH, PROPERTY_SCOPE_FIELDS_NEW.MATERIAL_PRIMARY,
      PROPERTY_SCOPE_FIELDS_NEW.MAX_PRICED_QTY, PROPERTY_SCOPE_FIELDS_NEW.NET_WEIGHT, PROPERTY_SCOPE_FIELDS_NEW.PLANTS,
      PROPERTY_SCOPE_FIELDS_NEW.PRICING_FLAG, PROPERTY_SCOPE_FIELDS_NEW.PRIMARY_L1_CATEGORY, PROPERTY_SCOPE_FIELDS_NEW.PRIMARY_L2_CATEGORY,
      PROPERTY_SCOPE_FIELDS_NEW.PRODUCT_DESIGN, PROPERTY_SCOPE_FIELDS_NEW.SALES_AREAS, PROPERTY_SCOPE_FIELDS_NEW.SKU, PROPERTY_SCOPE_FIELDS_NEW.VARIANT,
      PROPERTY_SCOPE_FIELDS_NEW.WEIGHT_UNIT_OF_MEASURE, ...this.BUY_BOX_NEW]
  },
  CART: [PROPERTY_SCOPE_FIELDS.CHARACTERISTICS, PROPERTY_SCOPE_FIELDS.BRAND_NAME, PROPERTY_SCOPE_FIELDS.SALES_AREAS,
    PROPERTY_SCOPE_FIELDS.PLANTS, PROPERTY_SCOPE_FIELDS.PRODUCT_DESIGN, PROPERTY_SCOPE_FIELDS.DESIGN_SKETCH,
    PROPERTY_SCOPE_FIELDS.DEFAULT_PLANT_CODE, PROPERTY_SCOPE_FIELDS.LOCALES, PROPERTY_SCOPE_FIELDS.VARIANT,
    PROPERTY_SCOPE_FIELDS.VARIANTS, PROPERTY_SCOPE_FIELDS.LEGACYSKU, PROPERTY_SCOPE_FIELDS.SUCCESSOR,
    PROPERTY_SCOPE_FIELDS.SKU, PROPERTY_SCOPE_FIELDS.CUSTOMER_MAPPINGS],
  get SUCCESSOR_POPUP() {
    return [
      PROPERTY_SCOPE_FIELDS.BRAND_NAME, PROPERTY_SCOPE_FIELDS.LOCALES, PROPERTY_SCOPE_FIELDS.SKU,
      PROPERTY_SCOPE_FIELDS.PLANTS, PROPERTY_SCOPE_FIELDS.DESIGN_SKETCH,
      PROPERTY_SCOPE_FIELDS.PRODUCT_DESIGN, PROPERTY_SCOPE_FIELDS.DEFAULT_PLANT_CODE]
  },
  get PLANTS_ONLY() {
    return [PROPERTY_SCOPE_FIELDS.PLANTS, PROPERTY_SCOPE_FIELDS.ALTERNATIVE_PLANT_CODE, PROPERTY_SCOPE_FIELDS.DEFAULT_PLANT_CODE]
  },
  get PLANTS_ONLY_NEW() {
    return [PROPERTY_SCOPE_FIELDS_NEW.PLANTS]
  },
  get VARIANT_ITEMS() {
    return [PROPERTY_SCOPE_FIELDS.PRODUCT_DESIGN, PROPERTY_SCOPE_FIELDS.VARIANT, PROPERTY_SCOPE_FIELDS.DEFAULT_PLANT_CODE, PROPERTY_SCOPE_FIELDS.PLANTS, PROPERTY_SCOPE_FIELDS.SKU, PROPERTY_SCOPE_FIELDS.SALES_AREAS,
      PROPERTY_SCOPE_FIELDS.PRIMARY_L2_CATEGORY, PROPERTY_SCOPE_FIELDS.DIMENSION_STRING,
      PROPERTY_SCOPE_FIELDS.MEASUREMENT, PROPERTY_SCOPE_FIELDS.LEGACYSKU, PROPERTY_SCOPE_FIELDS.MATERIAL_PRIMARY, PROPERTY_SCOPE_FIELDS.CHARACTERISTICS]
  },
  get VARIANT_ITEMS_NEW() {
    return [PROPERTY_SCOPE_FIELDS_NEW.PRODUCT_DESIGN, PROPERTY_SCOPE_FIELDS_NEW.VARIANT, PROPERTY_SCOPE_FIELDS_NEW.PLANTS,
      PROPERTY_SCOPE_FIELDS_NEW.SKU, PROPERTY_SCOPE_FIELDS_NEW.SALES_AREAS, PROPERTY_SCOPE_FIELDS_NEW.PRIMARY_L2_CATEGORY,
      PROPERTY_SCOPE_FIELDS_NEW.MATERIAL_PRIMARY, PROPERTY_SCOPE_FIELDS_NEW.CHARACTERISTICS]
  },
  get HYDRAULIC_SELECTOR() {
    return [PROPERTY_SCOPE_FIELDS.SKU, PROPERTY_SCOPE_FIELDS.DESIGN_SKETCH]
  },
  get HYDRAULIC_SELECTOR_NEW() {
    return [PROPERTY_SCOPE_FIELDS_NEW.SKU, PROPERTY_SCOPE_FIELDS_NEW.DESIGN_SKETCH]
  }
})

export const PROPERTY_SCOPE_DYNAMIC = Object.freeze({
  FST_DYNAMIC: [],
  BUY_BOX: [],
  get CATEGORY_PAGE() {
    return []
  },
  get SEARCH_OVERLAY() {
    return []
  },
  get SEARCH_RESULTS() {
    return []
  },
  get PRODUCT_DETAIL() {
    return []
  },
  CART: []
})

export const CANONICAL_LINK_REGEXP = /^\/(global|na)\/(.*)$/
export const FLOAT_REGEXP = /^[0-9]*\.?[0-9]*$/

export const DEFAULT_CUSTOMER_CURRENCY_FORMAT = Object.freeze({
  separator: '.',
  groupSeparator: '',
  groupSize: 3,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

export const DEFAULT_FORWARDER_UUID = '11111111-1111-1111-1111-111111111111'

export const ASSET_TYPES = Object.freeze({
  ProductDataSheet: 'ProductDataSheet',
  Other: 'Other'
})

export const MAX_PRICE_AGE = 60 * 60 * 1000 // 1 hour

export const FILENAME_REGEX = /([^/]*\/)+(?<filename>[^/]+\.pdf)(\?.+)?$/

export const TRANSLATION_HELPER_CONSTS = Object.freeze({
  defaultEntity: 'general'
})

export const FST_REQUEST_AUTH_HEADER = Object.freeze({
  HASH: 'Fst-Custom-Source',
  TIMESTAMP: 'Fst-Custom-Timestamp'
})

export const EXCLUDABLE_WARNING_KEYS = Object.freeze({
  QuantityRounded: 'Quantity_Rounded'
})

export const PLURALIZATION_KEYS = Object.freeze([
  'deliveryOptionDeliveryDays',
  'pieces',
  'piecesKG',
  'piecesM',
  'days'
])

export const HYDRAULIC_SELECTOR_SEALS_INPUTS = Object.freeze({
  'wiper': {
    translationKey: 'wiper',
    items: [
      {dataField: 'rodDiameter', translationKey: 'rodDiameter', order: 0, required: true},
      {dataField: 'outerDiameter', translationKey: 'outer_diameter', order: 1},
      {dataField: 'installationSpaceWidth', translationKey: 'installationSpaceWidth', order: 2}
    ],
    complexInstallationSpace: [
      {dataField: 'outerDiameter1', translationKey: 'diameter', order: 3},
      {dataField: 'installationSpaceWidth1', translationKey: 'installationSpaceWidth1', order: 4}
    ]
  },
  'rodSeal': {
    translationKey: 'rodSeal',
    items: [
      {dataField: 'rodDiameter', translationKey: 'rodDiameter', order: 0, required: true},
      {dataField: 'outerDiameter', translationKey: 'outer_diameter', order: 1},
      {dataField: 'installationSpaceWidth', translationKey: 'installationSpaceWidth', order: 2}
    ],
    complexInstallationSpace: null
  },
  'rodGuide': {
    translationKey: 'rodGuide',
    items: [
      {dataField: 'rodDiameter', translationKey: 'rodDiameter', order: 0, required: true},
      {dataField: 'outerDiameter', translationKey: 'outer_diameter', order: 1},
      {dataField: 'installationSpaceWidth', translationKey: 'installationSpaceWidth', order: 2}
    ],
    complexInstallationSpace: null
  },
  'bufferSeal': {
    translationKey: 'bufferSeal',
    items: [
      {dataField: 'rodDiameter', translationKey: 'rodDiameter', order: 0, required: true},
      {dataField: 'outerDiameter', translationKey: 'outer_diameter', order: 1},
      {dataField: 'installationSpaceWidth', translationKey: 'installationSpaceWidth', order: 2}
    ],
    complexInstallationSpace: null
  },
  'pistonSeal': {
    translationKey: 'pistonSeal',
    items: [
      {dataField: 'innerDiameter', translationKey: 'innerDiameter', order: 0},
      {dataField: 'boreDiameter', translationKey: 'boreDiameter', order: 1, required: true},
      {dataField: 'installationSpaceWidth', translationKey: 'installationSpaceWidth', order: 2}
    ],
    complexInstallationSpace: [
      {dataField: 'innerDiameter1', translationKey: 'diameter', order: 3},
      {dataField: 'installationSpaceWidth1', translationKey: 'installationSpaceWidth1', order: 4}
    ]
  },
  'pistonGuide': {
    translationKey: 'pistonGuide',
    items: [
      {dataField: 'innerDiameter', translationKey: 'inner_diameter', order: 0},
      {dataField: 'boreDiameter', translationKey: 'boreDiameter', order: 1, required: true},
      {dataField: 'installationSpaceWidth', translationKey: 'installationSpaceWidth', order: 2}
    ],
    complexInstallationSpace: null
  }
})

export const HYDRAULIC_SELECTOR_CYLINDER_IMAGES = [
  {src: '/img/FST_Asset_Zylinderspezifikationen_default.png', alt: 'default', key: 'default'},
  {src: '/img/FST_Asset_Zylinderspezifikationen_Stangendurchmesser.png', alt: 'rod', key: 'rod'},
  {src: '/img/FST_Asset_Zylinderspezifikationen_Zylinderdurchmesser.png', alt: 'cylinder', key: 'cylinder'}
]

export const IMAGES_FOR_TABS = {
  wiper: {
    src: [
      '/img/FST_Asset_Wiper-1.svg',
      '/img/FST_Asset_Wiper-2.svg'
    ]
  },
  rodSeal: {
    src: '/img/FST_Asset_Rod_Seal.svg'
  },
  bufferSeal: {
    src: '/img/FST_Asset_Buffer_Seal.svg'
  },
  rodGuide: {
    src: '/img/FST_Asset_Rod_Guide.svg'
  },
  pistonSeal: {
    src: [
      '/img/FST_Asset_Piston_Seal-1.svg',
      '/img/FST_Asset_Piston_Seal-2.svg'
    ]
  },
  pistonGuide: {
    src: '/img/FST_Asset_Piston_Guide.svg'
  }
}
export const HYDRAULIC_INSTALLATION_TABS_STRICT_ERRORS = [
  'Hydraulic_RequiredPropertyMissing'
]

export const HYDRAULIC_MESSAGE_KEYS =  Object.freeze({
  BufferMessage: 'Hydraulic_BufferSealsNotNeededForOperatingTimeLowMedium'
})

export const HYDRAULIC_PROFILES = [
  'FRA', 'HDP 330', 'KB', 'OMKPU', 'SK300', 'DMRW2', 'FRI', 'HDR-2C', 'LF300', 'PRW1', 'PU5', 'SB', 'T20', 'T18', 'T22', 'T19', 'AUASR', 'KI 310', 'SM', 'EKF', 'KF', 'KBK', 'SBK', 'EMPTYBUFFERSEAL'
]

export const HYDRAULIC_SELECTOR_METADATA = Object.freeze(['temperatureMin',
  'temperatureMax',
  'pressureMax',
  'pressureConst',
  'speedMax',
  'cylinderType',
  'dirtExposure',
  'operatingDuration'
])

export const DIST_SEARCH_CLASSIFICATION_DROPDOWN_VALUES = Object.freeze({
  sealing: 'sealing',
  aerospace: 'aerospace',
  accumulator: 'accumulator'
})

export const DIST_SEARCH_CLASSIFICATION_MAPPING = Object.freeze({
  sealing: ['Approved Partner', 'Preferred Partner', 'Freudenberg Industrial Distributor', 'Dichtomatik Distributor'],
  aerospace: ['Aerospace Approved Partner', 'Aerospace Partner', 'Aerospace Preferred Partner'],
  accumulator: ['Accumulator Partner']
})

export const PARTNER_SINGLE_STATUS = Object.freeze({
  Purchase: 'Purchase',
  PurchaseFactoryOrder: 'PurchaseFactoryOrder',
  Request: 'Request',
  RequestFactoryOrder: 'RequestFactoryOrder'
})

export const API_INDICATORS = Object.freeze({
  PARTNER_HUB: 'partnerHub',
  ACCUMULATOR_SELECTOR: 'accumulatorSelector'
})
