import validate from "/home/vsts/work/1/s/src/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/home/vsts/work/1/s/src/middleware/01.auth.global.ts";
import _02_45permission_45check_45global from "/home/vsts/work/1/s/src/middleware/02.permission_check.global.js";
import _03_45wizard_45global from "/home/vsts/work/1/s/src/middleware/03.wizard.global.js";
import _05_45breadcrumb_45global from "/home/vsts/work/1/s/src/middleware/05.breadcrumb.global.ts";
import _06_45meta_45global from "/home/vsts/work/1/s/src/middleware/06.meta.global.ts";
import _07_45newsearch_45global from "/home/vsts/work/1/s/src/middleware/07.newsearch.global.ts";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45permission_45check_45global,
  _03_45wizard_45global,
  _05_45breadcrumb_45global,
  _06_45meta_45global,
  _07_45newsearch_45global
]
export const namedMiddleware = {}